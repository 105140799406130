import React, { useState, useEffect } from "react";
import "../Assets/css/style.css";
import Logo from "../Assets/images/preloader.png"
const Preloader = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        setIsLoaded(true);
      }, 1000);
      setTimeout(() => {
        setIsRemoved(true);
      }, 2000);
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  if (isRemoved) return null;

  return (
    <div id="preloader" className={isLoaded ? "loaded" : ""}>
      <div className="text-center w-100">
        <h2>Welcome to</h2>
        <img src={Logo} className="img-fluid" loading="lazy" alt="logo"  />
      </div>
      <div className="line"></div>
    </div>
  );
};

export default Preloader;
