import "./App.css";
import React, { useState, useEffect } from "react";
import Preloader from "./components/Preloader";
import { lazy, Suspense } from "react";
import Loading from "./components/Loading";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";


function App() {
  const [load, upadateLoad] = useState(true);
  const Home = lazy(() => import("./pages/Home"));
  const Services = lazy(() => import("./pages/Services"));
  const Resources = lazy(() => import("./pages/Resourses"));
  const Career = lazy(() => import("./pages/Career"));
  const Contact = lazy(() => import("./pages/Contact"));
  const [message, setMessage] = useState('');



  return (
    <Router>
      <Preloader />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route
          path="/home"
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/services"
          element={
            <Suspense fallback={<Loading />}>
              <Services />
            </Suspense>
          }
        />
        <Route
          path="/resources"
          element={
            <Suspense fallback={<Loading />}>
              <Resources />
            </Suspense>
          }
        />

        <Route
          path="/career"
          element={
            <Suspense fallback={<Loading />}>
              <Career />
            </Suspense>
          }
        />

        <Route
          path="/contact"
          element={
            <Suspense fallback={<Loading />}>
              <Contact />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
