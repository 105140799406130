

const Loading = () =>{
    return(
        <div className="vh-100 d-flex align-items-center justify-content-center">
            <p>loading...</p>
        </div>
    )
}

export default Loading